import {
  SET_ITEMS_FROM_DISPENSING,
  SET_ACTIVE_PATIENT_TAB,
  SET_PREVIOUS_TRANSACTION,
} from "../types";

const initialState = {
  dispensed_items: [],
  dispensing_id: "",
  previousTransactionID: 0,
  previousTransactionData: [],
  active_patient_tab: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ITEMS_FROM_DISPENSING:
      return {
        ...state,
        dispensed_items: action.payload.items,
        dispensing_id: action.payload.dispensing_id,
      };
    case SET_PREVIOUS_TRANSACTION:
      return {
        ...state,
        previousTransactionID: action.payload.id,
        previousTransactionData: action.payload.data,
      };
    case SET_ACTIVE_PATIENT_TAB:
      return {
        ...state,
        active_patient_tab: action.payload,
      };
    default:
      return state;
  }
}
