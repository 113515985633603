import {
    LOADING_GET_SERVICES,
    SET_SERVICES,
    GET_SERVICES_ERROR,
    ADD_SERVICE,
    LOADING_ADD_SERVICE,
    ADD_SERVICE_ERROR,
    UPDATE_SERVICE,
    UPDATE_SERVICE_ERROR,
    LOADING_UPDATE_SERVICE,
    DELETE_SERVICE,
    LOADING_DELETE_SERVICE,
    DELETE_SERVICE_ERROR,
    } from '../types';
    
    const initialState = {
      services: [],
      loading_services: false,
      services_errors: null,
      loading_add_service: false,
      add_service_errors : null,
      loading_update_service: false,
      update_service_errors: null,
      loading_delete_service: false,
      delete_service_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_SERVICES:
          return {
            ...state,
            loading_services: true,
            services_errors: null,
          };
        case LOADING_UPDATE_SERVICE:
          return {
            ...state,
            loading_update_service: true,
            update_service_errors: null,
          };
        case LOADING_DELETE_SERVICE:
          return {
            ...state,
            loading_delete_service: true,
            delete_service_errors: null,
          };
        case LOADING_ADD_SERVICE:
          return {
            ...state,
            loading_add_service: true,
            add_service_errors : null,
          };
        case SET_SERVICES:
          return {
            ...state,
            services: action.payload.data,
            loading_services: false,
            services_errors: null,
          };
        case ADD_SERVICE:
            return {
              ...state,
              services:[...state.services,action.payload],
              loading_add_service: false,
              add_service_errors : null,
            };
        case UPDATE_SERVICE:
          let service_index = state.services.findIndex(x => x.id === action.payload.id);
          const serviceUpdate = [...state.services];
          serviceUpdate[service_index] = action.payload;
          return {
            ...state,
            services: [...serviceUpdate],
            loading_update_service: false,
            update_service_errors: null,
            loading_add_service: false,
            add_service_errors : null,
          };
        case DELETE_SERVICE:
            let del = state.services.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              services: state.services.filter((item, index) => index !== del),
              loading_delete_service: false,
              delete_service_errors: null,
            };
        case GET_SERVICES_ERROR:
          return {
            ...state,
            loading_services: false,
            services_errors: action.payload,
          };
        case ADD_SERVICE_ERROR:
          return {
            ...state,
            loading_add_service: false,
            add_service_errors : action.payload,
          };
        case UPDATE_SERVICE_ERROR:
          return {
            ...state,
            loading_update_service: false,
            update_service_errors: action.payload,
          };
        case DELETE_SERVICE_ERROR:
          return {
            ...state,
            loading_delete_service: false,
            delete_service_errors: action.payload,
          };
       
        default:
          return state;
      }
    }
    