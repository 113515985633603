import {
  LOADING_GET_NOTES,
  SET_NOTES,
  GET_NOTES_ERROR,
  ADD_NOTE,
  LOADING_ADD_NOTE,
  ADD_NOTE_ERROR,
  UPDATE_NOTE,
  UPDATE_NOTE_ERROR,
  LOADING_UPDATE_NOTE,
  DELETE_NOTE,
  LOADING_DELETE_NOTE,
  DELETE_NOTE_ERROR,
} from "../types";

const initialState = {
  notes: [],
  types: [],
  categories: [],
  loading_notes: false,
  notes_errors: null,
  loading_types: false,
  types_errors: null,
  loading_categories: false,
  categories_errors: null,
  loading_add_note: false,
  add_note_errors: null,
  loading_update_note: false,
  update_note_errors: null,
  loading_delete_note: false,
  delete_note_errors: null,
  loading_add_category: false,
  add_category_errors: null,
  loading_update_category: false,
  update_category_errors: null,
  loading_delete_category: false,
  delete_category_errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_NOTES:
      return {
        ...state,
        loading_notes: true,
        notes_errors: null,
      };
    case LOADING_UPDATE_NOTE:
      return {
        ...state,
        loading_update_note: true,
        update_note_errors: null,
        loading_add_note: true,
        add_note_errors: null,
      };
    case LOADING_DELETE_NOTE:
      return {
        ...state,
        loading_delete_note: true,
        delete_note_errors: null,
      };
    case LOADING_ADD_NOTE:
      return {
        ...state,
        loading_add_note: true,
        add_note_errors: null,
      };
    case SET_NOTES:
      return {
        ...state,
        notes: action.payload.data,
        loading_notes: false,
        notes_errors: null,
      };
    case ADD_NOTE:
      return {
        ...state,
        notes: [...state.notes, action.payload],
        loading_add_note: false,
        add_note_errors: null,
      };
    case UPDATE_NOTE:
      let NOTE_index = state.notes.findIndex((x) => x.id === action.payload.id);
      const NOTEUpdate = [...state.notes];
      NOTEUpdate[NOTE_index] = action.payload;
      return {
        ...state,
        notes: [...NOTEUpdate],
        loading_update_note: false,
        update_note_errors: null,
        add_note_errors: null,
        loading_add_note: false,
      };
    case DELETE_NOTE:
      let del_note = state.notes.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        notes: state.notes.filter((item, index) => index !== del_note),
        loading_delete_note: false,
        delete_note_errors: null,
      };
    case GET_NOTES_ERROR:
      return {
        ...state,
        loading_notes: false,
        notes_errors: action.payload,
      };
    case ADD_NOTE_ERROR:
      return {
        ...state,
        loading_add_note: false,
        add_note_errors: action.payload,
      };
    case UPDATE_NOTE_ERROR:
      return {
        ...state,
        loading_update_note: false,
        update_note_errors: action.payload,
        loading_add_note: false,
        add_note_errors: action.payload,
      };
    case DELETE_NOTE_ERROR:
      return {
        ...state,
        loading_delete_note: false,
        delete_note_errors: action.payload,
      };
    default:
      return state;
  }
}
