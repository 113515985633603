import {
    LOADING_GET_RECALLS,
    SET_RECALLS,
    GET_RECALLS_ERROR,
    ADD_RECALL,
    LOADING_ADD_RECALL,
    ADD_RECALL_ERROR,
    UPDATE_RECALL,
    UPDATE_RECALL_ERROR,
    LOADING_UPDATE_RECALL,
    DELETE_RECALL,
    LOADING_DELETE_RECALL,
    DELETE_RECALL_ERROR,
    LOADING_GET_RECALL_TYPES,
    LOADING_GET_RECALL_CATEGORIES,
    SET_RECALL_CATEGORIES,
    SET_RECALL_TYPES,
    GET_RECALL_CATEGORIES_ERROR,
    GET_RECALL_TYPES_ERROR,
    ADD_RECALL_CATEGORY,
    LOADING_ADD_RECALL_CATEGORY,
    ADD_RECALL_CATEGORY_ERROR,
    LOADING_UPDATE_RECALL_CATEGORY,
    UPDATE_RECALL_CATEGORY,
    UPDATE_RECALL_CATEGORY_ERROR,
    LOADING_DELETE_RECALL_CATEGORY,
    DELETE_RECALL_CATEGORY,
    DELETE_RECALL_CATEGORY_ERROR
    } from '../types';
    
    const initialState = {
      recalls: [],
      types:[],
      categories:[],
      total: 0,
      per_page: 0,
      loading_recalls: false,
      recalls_errors: null,
      loading_types: false,
      types_errors: null,
      loading_categories: false,
      categories_errors: null,
      loading_add_recall: false,
      add_recall_errors : null,
      loading_update_recall: false,
      update_recall_errors: null,
      loading_delete_recall: false,
      delete_recall_errors: null,
      loading_add_category: false,
      add_category_errors : null,
      loading_update_category: false,
      update_category_errors: null,
      loading_delete_category: false,
      delete_category_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_RECALLS:
          return {
            ...state,
            loading_recalls: true,
            recalls_errors: null,
          };
        case LOADING_GET_RECALL_CATEGORIES:
          return {
            ...state,
            loading_categories: true,
            categories_errors: null,
          };
        case LOADING_GET_RECALL_TYPES:
          return {
            ...state,
            loading_types: true,
            types_errors: null,
          };
        case LOADING_UPDATE_RECALL:
          return {
            ...state,
            loading_update_recall: true,
            update_recall_errors: null,
            loading_add_recall: true,
            add_recall_errors : null,
          };
        case LOADING_DELETE_RECALL:
          return {
            ...state,
            loading_delete_recall: true,
            delete_recall_errors: null,
          };
        case LOADING_ADD_RECALL:
          return {
            ...state,
            loading_add_recall: true,
            add_recall_errors : null,
          };
        case SET_RECALLS:
          return {
            ...state,
            recalls: action.payload.data,
            total: action.payload.total,
            per_page: action.payload.per_page,
            loading_recalls: false,
            recalls_errors: null,
          };
        case SET_RECALL_TYPES:
          return {
            ...state,
            types: action.payload,
            loading_types: false,
            types_errors: null,
          };
        case SET_RECALL_CATEGORIES:
          return {
            ...state,
            categories: action.payload,
            loading_categories: false,
            categories_errors: null,
          };
        case ADD_RECALL:
          let ttl = state.total + 1;
            return {
              ...state,
              recalls:[...state.recalls,action.payload],
              total: ttl,
              loading_add_recall: false,
              add_recall_errors : null,
            };
        case UPDATE_RECALL:
          let RECALL_index = state.recalls.findIndex(x => x.id === action.payload.id);
          const RECALLUpdate = [...state.recalls];
          RECALLUpdate[RECALL_index] = action.payload;
          return {
            ...state,
            recalls: [...RECALLUpdate],
            loading_update_recall: false,
            update_recall_errors: null,
            add_recall_errors : null,
            loading_add_recall: false,
          };
        case DELETE_RECALL:
            let del_recall = state.recalls.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              recalls: state.recalls.filter((item, index) => index !== del_recall),
              loading_delete_recall: false,
              delete_recall_errors: null,
            };
        case GET_RECALLS_ERROR:
          return {
            ...state,
            loading_recalls: false,
            recalls_errors: action.payload,
          };
        case GET_RECALL_TYPES_ERROR:
          return {
            ...state,
            loading_types: false,
            types_errors: action.payload,
          };
        case ADD_RECALL_ERROR:
          return {
            ...state,
            loading_add_recall: false,
            add_recall_errors : action.payload,
          };
        case GET_RECALL_CATEGORIES_ERROR:
          return {
            ...state,
            loading_categories: false,
            categories_errors: action.payload,
          };
        case UPDATE_RECALL_ERROR:
          return {
            ...state,
            loading_update_recall: false,
            update_recall_errors: action.payload,
            loading_add_recall: false,
            add_recall_errors : action.payload,
          };
        case DELETE_RECALL_ERROR:
          return {
            ...state,
            loading_delete_recall: false,
            delete_recall_errors: action.payload,
          };

          case LOADING_UPDATE_RECALL_CATEGORY:
            return {
              ...state,
              loading_update_category: true,
              update_category_errors: null,
            };
          case LOADING_DELETE_RECALL_CATEGORY:
            return {
              ...state,
              loading_delete_category: true,
              delete_category_errors: null,
            };
          case LOADING_ADD_RECALL_CATEGORY:
            return {
              ...state,
              loading_add_category: true,
              add_category_errors : null,
            };
          case ADD_RECALL_CATEGORY:
              return {
                ...state,
                categories:[...state.categories,action.payload],
                loading_add_category: false,
                add_category_errors : null,
              };
          case UPDATE_RECALL_CATEGORY:
            let category_index = state.categories.findIndex(x => x.id === action.payload.id);
            const categoryUpdate = [...state.categories];
            categoryUpdate[category_index] = action.payload;
            return {
              ...state,
              categories: [...categoryUpdate],
              loading_update_category: false,
              update_category_errors: null,
              loading_add_category: false,
              add_category_errors : null,
            };
          case DELETE_RECALL_CATEGORY:
              let del = state.categories.findIndex(x => x.id === action.payload.id);
              return {
                ...state,
                categories: state.categories.filter((item, index) => index !== del),
                loading_delete_category: false,
                delete_category_errors: null,
              };
          case ADD_RECALL_CATEGORY_ERROR:
            return {
              ...state,
              loading_add_category: false,
              add_category_errors : action.payload,
            };
          case UPDATE_RECALL_CATEGORY_ERROR:
            return {
              ...state,
              loading_update_category: false,
              update_category_errors: action.payload,
            };
          case DELETE_RECALL_CATEGORY_ERROR:
            return {
              ...state,
              loading_delete_category: false,
              delete_category_errors: action.payload,
            };
       
        default:
          return state;
      }
    }
    