import {
    LOADING_GET_PRESCIPTION_DATA,
    SET_PRESCRIPTION_DATA,
    GET_PRESCIPTION_ERROR,
    CHANGE_PRESCRIPTION_USAGE,
  } from '../types';
  import getPrescriptionObjects from "../../shared/functions/getPrescriptionObjects";
  
  const initialState = {
    datas: [],
    data: {},
    right_eye_sphere:[],
    right_eye_cylinder:[],
    left_eye_sphere:[],
    left_eye_cylinder:[],
    single_pd:[],
    right_pd:[],
    left_pd:[],
    left_add:[],
    right_add:[],
    loading_data: false,
    get_data_errors: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_PRESCIPTION_DATA:
        return {
          ...state,
          loading_data: true,
          get_data_errors: null,
        };
     
      case SET_PRESCRIPTION_DATA:
        return {
          ...state,
          datas: action.payload,
          loading_data: false,
          get_data_errors: null,
        };
      case CHANGE_PRESCRIPTION_USAGE:
        return {
          ...state,
          right_eye_sphere:getPrescriptionObjects(action.payload.right_eye_sphere),
          right_eye_cylinder:getPrescriptionObjects(action.payload.right_eye_cylinder),
          left_eye_sphere:getPrescriptionObjects(action.payload.left_eye_sphere),
          left_eye_cylinder:getPrescriptionObjects(action.payload.left_eye_cylinder),
          single_pd:getPrescriptionObjects(action.payload.single_pd),
          right_pd:getPrescriptionObjects(action.payload.right_pd),
          left_pd:getPrescriptionObjects(action.payload.left_pd),
          left_add:getPrescriptionObjects(action.payload.left_add),
          right_add:getPrescriptionObjects(action.payload.right_add),
        };
      case GET_PRESCIPTION_ERROR:
        return {
          ...state,
          loading_data: false,
          get_data_errors: action.payload,
        };
      default:
        return state;
    }
  }
  