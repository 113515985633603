import { useState } from "react";
import normal from './normal';
import dark from './dark';



export const useDarkMode = () => {
  const defaultValue = typeof localStorage.Theme !== "undefined" ? localStorage.Theme : "light";
  const defaultTheme  = String(defaultValue) === "light" ? normal : dark;
  const [theme, setTheme] = useState(defaultTheme);
  
  const toggleDarkMode = (currentMode) => {
      const updatedTheme = String(currentMode) === "light" ? normal : dark;
      setTheme(updatedTheme);
  }

  return [theme, toggleDarkMode];

}
