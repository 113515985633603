import {
  SET_DISPENSINGS,
  LOADING_GET_DISPENSINGS,
  GET_DISPENSINGS_ERROR,
  ADD_DISPENSING,
  ADD_DISPENSING_ERROR,
  LOADING_ADD_DISPENSING,
  UPDATE_DISPENSING,
  LOADING_UPDATE_DISPENSING,
  UPDATE_DISPENSING_ERROR,
  DELETE_DISPENSING,
  LOADING_DELETE_DISPENSING,
  DELETE_DISPENSING_ERROR,
  LOADING_PAY_DISPENSING,
  PAY_DISPENSING,
  PAY_DISPENSING_ERROR,
  LOADING_GET_DISPENSING_ITEMS,
  SET_DISPENSING_ITEMS,
  GET_DISPENSING_ITEMS_ERROR,
  LOADING_GET_STATUSES,
  GET_STATUSES_ERROR,
  SET_DISPENSING_STATUSES,
  LOADING_UPDATE_DISPENSING_STATUS,
  UPDATE_DISPENSING_STATUS,
  UPDATE_DISPENSING_STATUS_ERROR,
  CHANGE_DISPENSING_PRODUCT,

  } from '../types';
  
  const initialState = {
    dispensings: [],
    next_page_url:'',
    total:0,
    dispensing: {},
    variants:[],
    brand:{},
    items:[],
    statuses:[],
    loading_get_dispensings: false,
    get_dispensings_errors:null,
    loading_add_dispensings: false,
    add_dispensings_errors:null,
    loading_update_dispensings: false,
    update_dispensings_errors:null,
    loading_delete_dispensings: false,
    delete_dispensings_errors:null,
    loading_pay_dispensings: false,
    pay_dispensings_errors:null,
    loading_get_dispensing_items: false,
    get_dispensing_items_errors:null,
    loading_get_dispensing_statuses: false,
    get_dispensing_statuses_errors:null,
    loading_update_dispensing_status: false,
    update_dispensing_status_errors:null,

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_DISPENSINGS:
        return {
          ...state,
          loading_get_dispensings: true,
          get_dispensings_errors:null
        };
      case LOADING_UPDATE_DISPENSING_STATUS:
        return {
          ...state,
          loading_update_dispensing_status: true,
          update_dispensing_status_errors:null,
        };
      case LOADING_GET_STATUSES:
        return {
          ...state,
          loading_get_dispensing_statuses: true,
          get_dispensing_statuses_errors:null,
        };
      case LOADING_GET_DISPENSING_ITEMS:
        return {
          ...state,
          loading_get_dispensing_items: true,
           get_dispensing_items_errors:null,
        };
      case LOADING_PAY_DISPENSING:
        return {
          ...state,
          loading_pay_dispensings: true,
          pay_dispensings_errors:null
        };
      case LOADING_ADD_DISPENSING:
        return {
          ...state,
          loading_add_dispensings: true,
          add_dispensings_errors:null,
        };
      case LOADING_UPDATE_DISPENSING:
        return {
          ...state,
          loading_update_dispensings: true,
          update_dispensings_errors:null,
        };
      case LOADING_DELETE_DISPENSING:
        return {
          ...state,
          loading_delete_dispensings: true,
          delete_dispensings_errors:null,
        };
     
      case SET_DISPENSINGS:
        return {
          ...state,
          dispensings: action.payload.data,
          next_page_url: action.payload.next_page_url,
          total: action.payload.total,
          loading_get_dispensings: false,
          get_dispensings_errors:null
        };
      case SET_DISPENSING_STATUSES:
        return {
          ...state,
          statuses: action.payload,
          loading_get_dispensing_statuses: false,
          get_dispensing_statuses_errors:null,
        };
      case CHANGE_DISPENSING_PRODUCT:
        return {
          ...state,
          variants: action.payload.variants,
          brand: action.payload.brand,
        };
      case SET_DISPENSING_ITEMS:
        return {
          ...state,
          items: action.payload,
          loading_get_dispensing_items: false,
          get_dispensing_items_errors:null,
        };
      case ADD_DISPENSING:
          return {
            ...state,
            dispensings:[...state.dispensings,action.payload],
            loading_add_dispensings: false,
            add_dispensings_errors:null,
          };
      case UPDATE_DISPENSING:
            let dispensing_index = state.dispensings.findIndex(x => x.id === action.payload.id);
            const dispensingUpdate = [...state.dispensings];
            dispensingUpdate[dispensing_index] = action.payload;
            return {
              ...state,
              dispensings: [...dispensingUpdate],
              loading_update_dispensings: false,
              update_dispensings_errors:null,
      };
      case PAY_DISPENSING:
            let dispensing_pay_index = state.dispensings.findIndex(x => x.id === action.payload.id);
            const dispensingPayUpdate = [...state.dispensings];
            dispensingPayUpdate[dispensing_pay_index] = action.payload;
            return {
              ...state,
              dispensings: [...dispensingPayUpdate],
              loading_pay_dispensings: false,
              pay_dispensings_errors:null
      };
      case UPDATE_DISPENSING_STATUS:
            let dispensing_status_index = state.dispensings.findIndex(x => x.id === action.payload.id);
            const dispensingStatusUpdate = [...state.dispensings];
            dispensingStatusUpdate[dispensing_status_index] = action.payload;
            return {
              ...state,
              dispensings: [...dispensingStatusUpdate],
              loading_update_dispensing_status: false,
              update_dispensing_status_errors:null,
      };
      case DELETE_DISPENSING:
          let del_index = state.dispensings.findIndex(x => x.id === action.payload.id);
          let new_total = parseInt(state.total) - 1;
          return {
            ...state,
            total: new_total,
            dispensings: state.dispensings.filter((item, index) => index !== del_index),
            loading_delete_dispensings: false,
            delete_dispensings_errors:null,
      };
      case GET_DISPENSINGS_ERROR:
        return {
          ...state,
          loading_get_dispensings: false,
          get_dispensings_errors: action.payload
        };
      case ADD_DISPENSING_ERROR:
        return {
          ...state,
          loading_add_dispensings: false,
          add_dispensings_errors:action.payload.errors,
        };
      case PAY_DISPENSING_ERROR:
        return {
          ...state,
          loading_pay_dispensings: false,
          pay_dispensings_errors:action.payload
        };
      case UPDATE_DISPENSING_ERROR:
        return {
          ...state,
          loading_update_dispensings: false,
          update_dispensings_errors: action.payload,
        };
      case DELETE_DISPENSING_ERROR:
        return {
          ...state,
          loading_delete_dispensings: false,
          delete_dispensings_errors: action.payload,
        };
      case GET_DISPENSING_ITEMS_ERROR:
        return {
          ...state,
          loading_get_dispensing_items: false,
          get_dispensing_items_errors:action.payload,
        };
      case GET_STATUSES_ERROR:
        return {
          ...state,
          loading_get_dispensing_statuses: false,
          get_dispensing_statuses_errors:action.payload,
        };
      case UPDATE_DISPENSING_STATUS_ERROR:
        return {
          ...state,
          loading_update_dispensing_status: false,
          update_dispensing_status_errors:action.payload,
        };
      
      default:
        return state;
    }
  }
  