import {
  LOADING_GET_MESSAGES,
  SET_MESSAGES,
  GET_MESSAGES_ERROR,
  ADD_MESSAGE,
  LOADING_ADD_MESSAGE,
  ADD_MESSAGE_ERROR,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_ERROR,
  LOADING_UPDATE_MESSAGE,
  DELETE_MESSAGE,
  LOADING_DELETE_MESSAGE,
  DELETE_MESSAGE_ERROR,
  LOADING_GET_MESSAGES_CHANNELS,
  SET_MESSAGES_CHANNELS,
  GET_MESSAGES_ERROR_CHANNELS,
} from "../types";

const initialState = {
  messages: [],
  types: [],
  categories: [],
  loading_messages: false,
  messages_errors: null,
  loading_types: false,
  types_errors: null,
  loading_categories: false,
  categories_errors: null,
  loading_add_message: false,
  add_message_errors: null,
  loading_update_message: false,
  update_message_errors: null,
  loading_delete_message: false,
  delete_message_errors: null,
  loading_add_category: false,
  add_category_errors: null,
  loading_update_category: false,
  update_category_errors: null,
  loading_delete_category: false,
  delete_category_errors: null,
  channels: [],
  loadingChannels: false,
  channelsErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_MESSAGES:
      return {
        ...state,
        loading_messages: true,
        messages_errors: null,
      };
    case LOADING_UPDATE_MESSAGE:
      return {
        ...state,
        loading_update_message: true,
        update_message_errors: null,
        loading_add_message: true,
        add_message_errors: null,
      };
    case LOADING_DELETE_MESSAGE:
      return {
        ...state,
        loading_delete_message: true,
        delete_message_errors: null,
      };
    case LOADING_ADD_MESSAGE:
      return {
        ...state,
        loading_add_message: true,
        add_message_errors: null,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload.data,
        loading_messages: false,
        messages_errors: null,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
        loading_add_message: false,
        add_message_errors: null,
      };
    case UPDATE_MESSAGE:
      let MESSAGE_index = state.messages.findIndex(
        (x) => x.id === action.payload.id
      );
      const MESSAGEUpdate = [...state.messages];
      MESSAGEUpdate[MESSAGE_index] = action.payload;
      return {
        ...state,
        messages: [...MESSAGEUpdate],
        loading_update_message: false,
        update_message_errors: null,
        add_message_errors: null,
        loading_add_message: false,
      };
    case DELETE_MESSAGE:
      let del_message = state.messages.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        messages: state.messages.filter((item, index) => index !== del_message),
        loading_delete_message: false,
        delete_message_errors: null,
      };
    case GET_MESSAGES_ERROR:
      return {
        ...state,
        loading_messages: false,
        messages_errors: action.payload,
      };
    case ADD_MESSAGE_ERROR:
      return {
        ...state,
        loading_add_message: false,
        add_message_errors: action.payload,
      };
    case UPDATE_MESSAGE_ERROR:
      return {
        ...state,
        loading_update_message: false,
        update_message_errors: action.payload,
        loading_add_message: false,
        add_message_errors: action.payload,
      };
    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        loading_delete_message: false,
        delete_message_errors: action.payload,
      };
    case LOADING_GET_MESSAGES_CHANNELS:
      return {
        ...state,
        loadingChannels: true,
        channelsErrors: null,
      };
    case SET_MESSAGES_CHANNELS:
      return {
        ...state,
        loadingChannels: false,
        channels: action.payload.channels,
        channelsErrors: null,
      };
    case GET_MESSAGES_ERROR_CHANNELS:
      return {
        ...state,
        loadingChannels: false,
        channelsErrors: action.payload,
      };

    default:
      return state;
  }
}
