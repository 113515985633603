import React, { Component } from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ClearIcon from "@material-ui/icons/Clear";
import { Button, IconButton, Snackbar } from "@material-ui/core";

class ServiceWorkerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptRefresh: false,
      open: true,
    };
    this.handleRefreshForUpdate = this.handleRefreshForUpdate.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    serviceWorker.register({
      onUpdate: (registration) => {
        if (registration && registration.waiting) {
          this.setState({ promptRefresh: true });
        } else {
          this.setState({ promptRefresh: false });
        }
      },
    });
  }

  handleRefreshForUpdate = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then((reg) => {
          reg.waiting.postMessage({ type: "SKIP_WAITING" });
          window.location.reload();
        })
        .catch((err) => console.log("Could not get registration: ", err));
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const action = (
      <React.Fragment>
        <Button
          color="secondary"
          size="small"
          onClick={this.handleRefreshForUpdate}
        >
          Update
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleClose}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    const refreshButton = this.state.promptRefresh ? (
      // <Alert
      //   severity="info"
      //   action={
      //     <Button
      //       color="inherit"
      //       size="small"
      //       onClick={this.handleRefreshForUpdate}
      //     >
      //       Update
      //     </Button>
      //   }
      // >
      //   A new Version is available — check it out!
      // </Alert>
      <Snackbar
        open={this.state.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // autoHideDuration={6000}
        onClose={this.handleClose}
        message="A new Version is available — check it out!"
        action={action}
      />
    ) : null;

    return (
      <div className="App">
        {refreshButton}
        <App />
      </div>
    );
  }
}

export default ServiceWorkerWrapper;
