import {
    VARIANT_UPDATE,
    UNSET_VARIANT_UPDATE,
    SUPPLIER_UPDATE,
    UNSET_SUPPLIER_UPDATE,
    PRODUCT_UPDATE,
    UNSET_PRODUCT_UPDATE,
    BRAND_UPDATE,
    UNSET_BRAND_UPDATE,
    STORE_UPDATE,
    UNSET_STORE_UPDATE,
    MANUFACTURER_UPDATE,
    UNSET_MANUFACTURER_UPDATE
  } from '../types';
  
  const initialState = {
    variant_update: false,
    supplier_update: false,
    brand_update: false,
    store_update: false,
    product_update: false,
    manufacturer_update: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case VARIANT_UPDATE:
        return {
          ...state,
          variant_update: true
        };
      case UNSET_VARIANT_UPDATE:
        return {
          ...state,
          variant_update: false
        };
      case SUPPLIER_UPDATE:
        return {
          ...state,
          supplier_update: true
        };
      case UNSET_SUPPLIER_UPDATE:
        return {
          ...state,
          supplier_update: false
        };
      case BRAND_UPDATE:
        return {
          ...state,
          brand_update: true
        };
      case UNSET_BRAND_UPDATE:
        return {
          ...state,
          brand_update: false
        };
      case PRODUCT_UPDATE:
        return {
          ...state,
          product_update: true
        };
      case UNSET_PRODUCT_UPDATE:
        return {
          ...state,
          product_update: false
        };
      case STORE_UPDATE:
        return {
          ...state,
          store_update: true
        };
      case UNSET_STORE_UPDATE:
        return {
          ...state,
          store_update: false
        };
      case MANUFACTURER_UPDATE:
        return {
          ...state,
          manufacturer_update: true
        };
      case UNSET_MANUFACTURER_UPDATE:
        return {
          ...state,
          manufacturer_update: false
        };
      default:
        return state;
    }
  }
  