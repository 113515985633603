import {
  LOADING_GET_CLINICAL_RECORDS,
  LOADING_GET_CLINICAL_RECORD,
  SET_CLINICAL_RECORDS,
  SET_CLINICAL_RECORD,
  GET_CLINICAL_RECORDS_ERROR,
  GET_CLINICAL_RECORD_ERROR,
  ADD_CLINICAL_RECORD,
  LOADING_ADD_CLINICAL_RECORD,
  ADD_CLINICAL_RECORD_ERROR,
  UPDATE_CLINICAL_RECORD,
  UPDATE_CLINICAL_RECORD_ERROR,
  LOADING_UPDATE_CLINICAL_RECORD,
  DELETE_CLINICAL_RECORD,
  LOADING_DELETE_CLINICAL_RECORD,
  DELETE_CLINICAL_RECORD_ERROR,
  UPDATE_HISTORY_AND_SYMPTOMS,
  UPDATE_SUPPLEMENTARY,
  UPDATE_OCULAR_HEALTH,
  UPDATE_SUBJECTIVE,
  UPDATE_COMMENT_ADVICE,
  UPDATE_DRAWING_SECTION,
  UPDATE_IMAGE_SECTION,
  UPDATE_CONTACT_LENSES,
  SET_PATIENT_CLINICAL_STATUS,
  SELECT_CLINICAL_RECORD,
  UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION,
  UPDATE_MUSCLE_BALANCE_DRAWING_SECTION,
  CLONE_CLINICAL_RECORD,
  CLONE_SELECT_CLINICAL_RECORD,
} from "../types";

const initialState = {
  clinical_records: [],
  clinical_record: {},
  addClinicalRecord: false,
  selectedRecord: null,
  cloneSelectedRecord: null,
  historyAndSymptoms: {
    reason: "",
    visions: "",
    rx: "",
    lee: "",
    lme: "",
    vdu: "",
    health: "",
    medication: "",
    family_ocular: "",
    ocular: "",
    dm: false,
    glaucoma: false,
    amb: false,
    family_history: "",
    h_or_a: false,
    dip: false,
    flashes: false,
    floaters: false,
    cl: false,
    driver: false,
    notes: "",
  },
  supplementary: {
    motility_notes: "",
    distance_with_rx: false,
    near_with_rx: false,
    distance_without_rx: "",
    horizontal_distance_with_rx: "",
    vertical_distance_with_rx: "",
    horizontal_near_with_rx: "",
    vertical_near_with_rx: "",
    horizontal_distance_without_rx: "",
    vertical_distance_without_rx: "",
    horizontal_near_without_rx: "",
    vertical_near_without_rx: "",
    near_without_rx: "",
    motility: "",
    pupils: "",
    npc: "",
    supplementary_notes: "",
    perrl: false,
    no_rapd: false,
    present_rapd: false,
  },
  ocularHealth: {
    r_extarnal: "",
    r_lids_lashes: "",
    r_conjunctiva: "",
    r_cornea: "",
    r_anterior_chamber: "",
    r_lens: "",
    r_vitreous: "",
    r_discs: "",
    r_c_d: "",
    r_vessels: "",
    r_av: "",
    r_macula: "",
    r_fundus: "",
    r_notes: "",
    l_extarnal: "",
    l_lids_lashes: "",
    l_conjunctiva: "",
    l_cornea: "",
    l_anteriol_chamber: "",
    l_lens: "",
    l_vitreous: "",
    l_discs: "",
    l_c_d: "",
    l_vessels: "",
    l_av: "",
    l_macula: "",
    l_fundus: "",
    l_notes: "",
    predialtion_time: "",
    predialtion_tonometry_r: "",
    predialtion_tonometry_l: "",
    predialtion_tonometry_instrument: "",
    postdialtion_time: "",
    postdialtion_tonometry_r: "",
    postdialtion_tonometry_l: "",
    postdialtion_tonometry_instrument: "",
    ocular_health_note: "",
    drawing_notes: "",
  },
  subjective: {
    s_r_vision: "",
    s_r_va: "",
    s_r_sph: "+0.00",
    s_r_cyl: "+0.00",
    s_r_axis: "0",
    s_r_prisms: "",
    s_r_add: "",
    s_r_nprisms: "",
    s_r_nva: "",
    s_r_base: "",
    s_l_vision: "",
    s_l_va: "",
    s_l_sph: "+0.00",
    s_l_cyl: "+0.00",
    s_l_axis: "0",
    s_l_prisms: "",
    s_l_add: "",
    s_l_nprisms: "",
    s_l_nva: "",
    s_l_base: "",
    oldpx_l_near_pd: "",
    oldrx_r_sph: "+0.00",
    oldrx_r_cyl: "+0.00",
    oldrx_r_axis: "0",
    oldrx_r_prisms: "",
    oldrx_r_add: "",
    oldrx_r_n_prisms: "",
    oldrx_l_sph: "+0.00",
    oldrx_l_cyl: "+0.00",
    oldrx_l_axis: "0",
    oldrx_l_prisms: "",
    oldrx_l_add: "",
    oldrx_l_n_prisms: "",
    oldrx_r_vision: "",
    oldrx_r_va: "",
    oldrx_r_base: "",
    oldrx_r_nva: "",
    oldrx_l_vision: "",
    oldrx_l_va: "",
    oldrx_l_base: "",
    oldrx_l_nva: "",
    oldpx_r_sph: "+0.00",
    oldpx_r_cyl: "+0.00",
    oldpx_r_axis: "0",
    oldpx_r_prisms: "",
    oldpx_r_add: "",
    oldpx_r_n_prisms: "",
    oldpx_r_vision: "",
    oldpx_r_va: "",
    oldpx_r_base: "",
    oldpx_r_nva: "",
    oldpx_r_pd: "",
    oldpx_l_sph: "+0.00",
    oldpx_l_cyl: "+0.00",
    oldpx_l_axis: "0",
    oldpx_l_prisms: "",
    oldpx_l_add: "",
    oldpx_l_n_prisms: "",
    oldpx_l_vision: "",
    oldpx_l_va: "",
    oldpx_l_base: "",
    oldpx_l_nva: "",
    recommendation: "",
  },
  commentAdvice: {
    date: new Date(),
    final_comment: "",
    final_advice: "",
    recallcategoryid: null,
    type: null,
    comments: "",
    image: "",
  },
  drawingSection: {
    paths: [],
  },
  imageSection: {
    image: null,
  },
  muscleBalanceDrawingSection: {
    paths: [],
  },
  muscleBalanceImageSection: {
    image: null,
  },
  contactLenses: {
    r_sph: "+0.00",
    r_cyl: "+0.00",
    r_axis: "0",
    r_add: "",
    r_cl_fit: "",
    l_sph: "+0.00",
    l_cyl: "+0.00",
    l_axis: "0",
    l_add: "",
    l_cl_fit: "",
    prescription_type: "",
    recommendation: "",
  },
  loading_clinical_record: false,
  loading_clinical_records: false,
  clinical_records_errors: null,
  clinical_record_errors: null,
  loading_add_clinical_record: false,
  add_clinical_record_errors: null,
  loading_update_clinical_record: false,
  update_clinical_record_errors: null,
  loading_delete_clinical_record: false,
  delete_clinical_record_errors: null,
};

export default function (state = initialState, action) {
  let cloneRecord = false;

  switch (action.type) {
    case LOADING_GET_CLINICAL_RECORDS:
      return {
        ...state,
        loading_clinical_records: true,
        clinical_records_errors: null,
      };
    case LOADING_GET_CLINICAL_RECORD:
      return {
        ...state,
        loading_clinical_record: true,
        clinical_records_error: null,
      };
    case LOADING_UPDATE_CLINICAL_RECORD:
      return {
        ...state,
        loading_update_clinical_record: true,
        update_clinical_record_errors: null,
      };
    case LOADING_DELETE_CLINICAL_RECORD:
      return {
        ...state,
        loading_delete_clinical_record: true,
        delete_clinical_record_errors: null,
      };
    case LOADING_ADD_CLINICAL_RECORD:
      return {
        ...state,
        loading_add_clinical_record: true,
        add_clinical_record_errors: null,
      };
    case SET_CLINICAL_RECORDS:
      return {
        ...state,
        clinical_records: action.payload.data,
        loading_clinical_records: false,
        clinical_records_errors: null,
      };
    case CLONE_CLINICAL_RECORD:
      cloneRecord = true;
    case SET_CLINICAL_RECORD:
      const {
        advice,
        comment,
        image,
        recallcategoryid,
        type,
        recall_date,
        created_at,
        contactlense,
        preliminary,
        ocularhealth,
        refraction,
        supplementary,
      } = action.payload.record;

      if (cloneRecord) {
        delete action.payload.record.id;
        delete preliminary?.id;
        delete preliminary?.reason;
        delete ocularhealth?.id;
        delete refraction?.id;
        delete supplementary?.id;
        delete contactlense?.id;
      }

      return {
        ...state,
        clinical_record: action.payload.record,
        historyAndSymptoms: preliminary,
        ocularHealth: ocularhealth,
        subjective: refraction,
        supplementary: supplementary,
        contactLenses: contactlense ? contactlense : state.contactLenses,
        commentAdvice: {
          ...state.commentAdvice,
          date: cloneRecord ? null : recall_date,
          final_comment: cloneRecord ? null : comment,
          final_advice: cloneRecord ? null : advice,
          recallcategoryid: cloneRecord ? null : recallcategoryid,
          type: cloneRecord ? null : type,
          advice: cloneRecord ? null : advice,
          comment: cloneRecord ? null : comment,
          recall_date: cloneRecord ? null : recall_date,
          image,
          created_at,
        },
        loading_clinical_record: false,
        clinical_records_error: null,
      };
    case ADD_CLINICAL_RECORD:
      return {
        ...state,
        clinical_records: [...state.clinical_records, action.payload],
        loading_add_clinical_record: false,
        add_clinical_record_errors: null,
      };
    // case UPDATE_CLINICAL_RECORD:
    //   return {
    //     ...state,
    //     loading_add_clinical_record: false,
    //     add_clinical_record_errors: null,
    //   };
    case UPDATE_CLINICAL_RECORD:
      let clinical_record_index = state.clinical_records.findIndex(
        (x) => x.id === action.payload.id
      );
      const clinical_recordUpdate = [...state.clinical_records];
      clinical_recordUpdate[clinical_record_index] = action.payload;
      return {
        ...state,
        clinical_records: [...clinical_recordUpdate],
        loading_update_clinical_record: false,
        update_clinical_record_errors: null,
        loading_add_clinical_record: false,
        add_clinical_record_errors: null,
      };
    case DELETE_CLINICAL_RECORD:
      let del = state.clinical_records.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        clinical_records: state.clinical_records.filter(
          (item, index) => index !== del
        ),
        loading_delete_clinical_record: false,
        delete_clinical_record_errors: null,
      };
    case GET_CLINICAL_RECORDS_ERROR:
      return {
        ...state,
        loading_clinical_records: false,
        clinical_records_errors: action.payload,
      };
    case GET_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_clinical_record: false,
        clinical_record_errors: action.payload,
      };
    case ADD_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_add_clinical_record: false,
        add_clinical_record_errors: action.payload,
      };
    case UPDATE_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_update_clinical_record: false,
        update_clinical_record_errors: action.payload,
      };
    case DELETE_CLINICAL_RECORD_ERROR:
      return {
        ...state,
        loading_delete_clinical_record: false,
        delete_clinical_record_errors: action.payload,
      };
    case UPDATE_HISTORY_AND_SYMPTOMS:
      return {
        ...state,
        historyAndSymptoms: action.payload,
      };
    case UPDATE_SUPPLEMENTARY:
      return {
        ...state,
        supplementary: action.payload,
      };
    case UPDATE_OCULAR_HEALTH:
      return {
        ...state,
        ocularHealth: action.payload,
      };
    case UPDATE_SUBJECTIVE:
      return {
        ...state,
        subjective: action.payload,
      };
    case UPDATE_COMMENT_ADVICE:
      return {
        ...state,
        commentAdvice: action.payload,
      };
    case UPDATE_DRAWING_SECTION:
      return {
        ...state,
        drawingSection: action.payload,
      };
    case UPDATE_IMAGE_SECTION:
      return {
        ...state,
        imageSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_DRAWING_SECTION:
      return {
        ...state,
        muscleBalanceDrawingSection: action.payload,
      };
    case UPDATE_MUSCLE_BALANCE_UPDATE_IMAGE_SECTION:
      return {
        ...state,
        muscleBalanceImageSection: action.payload,
      };
    case UPDATE_CONTACT_LENSES:
      return {
        ...state,
        contactLenses: action.payload,
      };
    case SET_PATIENT_CLINICAL_STATUS:
      return {
        ...state,
        addClinicalRecord: action.payload,
        historyAndSymptoms: {
          reason: "",
          visions: "",
          rx: "",
          lee: "",
          lme: "",
          vdu: "",
          health: "",
          medication: "",
          family_ocular: "",
          ocular: "",
          dm: false,
          glaucoma: false,
          amb: false,
          family_history: "",
          h_or_a: false,
          dip: false,
          flashes: false,
          floaters: false,
          cl: false,
          driver: false,
          notes: "",
        },
        supplementary: {
          motility_notes: "",
          distance_with_rx: false,
          near_with_rx: false,
          distance_without_rx: "",
          horizontal_distance_with_rx: "",
          vertical_distance_with_rx: "",
          horizontal_near_with_rx: "",
          vertical_near_with_rx: "",
          horizontal_distance_without_rx: "",
          vertical_distance_without_rx: "",
          horizontal_near_without_rx: "",
          vertical_near_without_rx: "",
          near_without_rx: "",
          motility: "",
          pupils: "",
          npc: "",
          supplementary_notes: "",
          perrl: false,
          no_rapd: false,
          present_rapd: false,
        },
        ocularHealth: {
          r_extarnal: "",
          r_lids_lashes: "",
          r_conjunctiva: "",
          r_cornea: "",
          r_anterior_chamber: "",
          r_lens: "",
          r_vitreous: "",
          r_discs: "",
          r_c_d: "",
          r_vessels: "",
          r_av: "",
          r_macula: "",
          r_fundus: "",
          r_notes: "",
          l_extarnal: "",
          l_lids_lashes: "",
          l_conjunctiva: "",
          l_cornea: "",
          l_anteriol_chamber: "",
          l_lens: "",
          l_vitreous: "",
          l_discs: "",
          l_c_d: "",
          l_vessels: "",
          l_av: "",
          l_macula: "",
          l_fundus: "",
          l_notes: "",
          predialtion_time: "",
          predialtion_tonometry_r: "",
          predialtion_tonometry_l: "",
          predialtion_tonometry_instrument: "",
          postdialtion_time: "",
          postdialtion_tonometry_r: "",
          postdialtion_tonometry_l: "",
          postdialtion_tonometry_instrument: "",
          ocular_health_note: "",
          drawing_notes: "",
        },
        subjective: {
          s_r_vision: "",
          s_r_va: "",
          s_r_sph: "+0.00",
          s_r_cyl: "+0.00",
          s_r_axis: "60",
          s_r_prisms: "",
          s_r_add: "",
          s_r_nprisms: "",
          s_r_nva: "",
          s_r_base: "",
          s_l_vision: "",
          s_l_va: "",
          s_l_sph: "+0.00",
          s_l_cyl: "+0.00",
          s_l_axis: "60",
          s_l_prisms: "",
          s_l_add: "",
          s_l_nprisms: "",
          s_l_nva: "",
          s_l_base: "",
          oldpx_l_near_pd: "",
          oldrx_r_sph: "+0.00",
          oldrx_r_cyl: "+0.00",
          oldrx_r_axis: "60",
          oldrx_r_prisms: "",
          oldrx_r_add: "",
          oldrx_r_n_prisms: "",
          oldrx_l_sph: "+0.00",
          oldrx_l_cyl: "+0.00",
          oldrx_l_axis: "60",
          oldrx_l_prisms: "",
          oldrx_l_add: "",
          oldrx_l_n_prisms: "",
          oldrx_r_vision: "",
          oldrx_r_va: "",
          oldrx_r_base: "",
          oldrx_r_nva: "",
          oldrx_l_vision: "",
          oldrx_l_va: "",
          oldrx_l_base: "",
          oldrx_l_nva: "",
          oldpx_r_sph: "+0.00",
          oldpx_r_cyl: "+0.00",
          oldpx_r_axis: "60",
          oldpx_r_prisms: "",
          oldpx_r_add: "",
          oldpx_r_n_prisms: "",
          oldpx_r_vision: "",
          oldpx_r_va: "",
          oldpx_r_base: "",
          oldpx_r_nva: "",
          oldpx_r_pd: "",
          oldpx_l_sph: "+0.00",
          oldpx_l_cyl: "+0.00",
          oldpx_l_axis: "60",
          oldpx_l_prisms: "",
          oldpx_l_add: "",
          oldpx_l_n_prisms: "",
          oldpx_l_vision: "",
          oldpx_l_va: "",
          oldpx_l_base: "",
          oldpx_l_nva: "",
          recommendation: "",
        },
        commentAdvice: {
          date: new Date(),
          final_comment: "",
          final_advice: "",
          recallcategoryid: null,
          type: null,
          comments: "",
        },
        drawingSection: {
          paths: [],
        },
        imageSection: {
          image: null,
        },
        muscleBalanceDrawingSection: {
          paths: [],
        },
        muscleBalanceImageSection: {
          image: null,
        },
        contactLenses: {
          r_extarnal: "",
          r_lids_lashes: "",
          r_conjunctiva: "",
          r_cornea: "",
          r_anterior_chamber: "",
          r_lens: "",
          r_vitreous: "",
          r_discs: "",
          r_c_d: "",
          r_vessels: "",
          r_av: "",
          r_macula: "",
          r_fundus: "",
          r_notes: "",
          l_extarnal: "",
          l_lids_lashes: "",
          l_conjunctiva: "",
          l_cornea: "",
          l_anteriol_chamber: "",
          l_lens: "",
          l_vitreous: "",
          l_discs: "",
          l_c_d: "",
          l_vessels: "",
          l_av: "",
          l_macula: "",
          l_fundus: "",
          l_notes: "",
          r_sph: "+0.00",
          r_cyl: "+0.00",
          r_axis: "0",
          r_add: "",
          r_cl_fit: "",
          l_sph: "+0.00",
          l_cyl: "+0.00",
          l_axis: "0",
          l_add: "",
          l_cl_fit: "",
          prescription_type: "",
          recommendation: "",
        },
      };
    case SELECT_CLINICAL_RECORD:
      return {
        ...state,
        selectedRecord: action.payload,
      };
    case CLONE_SELECT_CLINICAL_RECORD:
      return {
        ...state,
        cloneSelectedRecord: action.payload,
      };

    default:
      return state;
  }
}
