import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

//colors
const black = "#3a3b3c";
const white = "#fff";
const darkBlack = "rgb(36, 40, 44)";
const successLight = "#81c784";
const successMain = "#4caf50";
const successDark = "#00b33c";
const infoLight = "#64b5f6";
const infoMain = "#2196f3";
const infoDark = "#1976d2";
const errorLight = "#e57373";
//const errorMain = "#f44336";
const errorDark = "#00b33c";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

//component custom colours - Abdulhakeem
const activeStateBorder = "#fff";
const activeStateBackground = "#def8c9";
const activeStateText = "#007046";
const inactiveStateBorder = "#fff";
const inactiveStateBackground = "#f8c9c9";
const inactiveStateText = "#700000";

//Custom States colours
const customSuccessBackgroundColor = "#f0fff3";
const customSuccessBorderColor = "#37b300";
const customSuccessTextColor = "#37b300";

const customWarningBackgroundColor = "#fffef0";
const customWarningBorderColor = "#f4e800";
const customWarningTextColor = "#f4e800";

const customDangerBackgroundColor = "#fff0f4";
const customDangerBorderColor = "#ff3b3b";
const customDangerTextColor = "#ff3b3b";

const customInfoBackgroundColor = "#f0ffff";
const customInfoBorderColor = "#00bff3";
const customInfoTextColor = "#00bff3";

const customDefaultBackgroundColor = "#fcfcfc";
const customDefaultBorderColor = "#8e8e8e";
const customDefaultTextColor = "#8e8e8e";

const tooltipBackground = "#555555";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

//color declarations
const primarycolor = "#e95419";
const secondarycolor = "#ffb74d";
const tertiarycolor = "#ee7425";
// const bgcolor = '#f4f6f8';
//const bgcolor = '#F5F6FB';
const bgcolor = "#fff";
const paperbgcolor = "#fff";
const neutralgrey = "#f0f0f0";
const bordercolor = "rgba(0, 0, 0, 0.12)";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: primarycolor,
    },
    secondary: {
      main: secondarycolor,
    },
    tertiary: {
      main: tertiarycolor,
    },
    error: {
      light: errorLight,
      main: red.A400,
      dark: errorDark,
    },
    background: {
      default: bgcolor,
      paper: paperbgcolor,
    },
    common: {
      black,
      white,
      darkBlack,
      red,
      neutralgrey,
      bordercolor,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    success: {
      light: successLight,
      main: successMain,
      dark: successDark,
    },
    info: {
      light: infoLight,
      main: infoMain,
      dark: infoDark,
    },
    productStateChipsActive: {
      border: activeStateBorder,
      background: activeStateBackground,
      text: activeStateText,
    },
    productStateChipsIntermediate: {
      border: activeStateBorder,
      background: activeStateBackground,
      text: activeStateText,
    },
    productStateChipsInactive: {
      border: inactiveStateBorder,
      background: inactiveStateBackground,
      text: inactiveStateText,
    },
    successState: {
      border: customSuccessBorderColor,
      background: customSuccessBackgroundColor,
      text: customSuccessTextColor,
    },
    warningState: {
      border: customWarningBorderColor,
      background: customWarningBackgroundColor,
      text: customWarningTextColor,
    },
    dangerState: {
      border: customDangerBorderColor,
      background: customDangerBackgroundColor,
      text: customDangerTextColor,
    },
    infoState: {
      border: customInfoBorderColor,
      background: customInfoBackgroundColor,
      text: customInfoTextColor,
    },
    defaultState: {
      border: customDefaultBorderColor,
      background: customDefaultBackgroundColor,
      text: customDefaultTextColor,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: tooltipBackground,
      },
      arrow: {
        color: tooltipBackground,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
    caption: {
      fontSize: 8,
    },
    subtitle1: {
      fontSize: 10,
    },
  },
  // typography: {
  //   // useNextVariants: true
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //     // '"Helvetica Neue"',
  //   ].join(','),
  // },
});

export default theme;
