import {
  LOADING_GET_APPOINTMENTS,
  SET_APPOINTMENTS,
  GET_APPOINTMENTS_ERROR,
  LOADING_ADD_APPOINTMENT,
  ADD_APPOINTMENT,
  ADD_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT,
  LOADING_UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_ERROR,
  DELETE_APPOINTMENT,
  LOADING_DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_ERROR,
  STATUSES_APPOINTMENT,
  LOADING_STATUSES_APPOINTMENT,
  STATUSES_APPOINTMENT_ERROR
  } from '../types';
  
  const initialState = {
    appointments: [],
    statuses:[],
    loading_appointments: false,
    appoinments_errors: null,
    loading_add_appointment: false,
    add_appoinment_errors: null,
    loading_update_appointment: false,
    update_appoinment_errors: null,
    loading_delete_appointment: false,
    delete_appoinment_errors: null,
    loading_get_appointment_statuses: false,
    get_appointment_statuses_error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_APPOINTMENTS:
        return {
          ...state,
          loading_appointments: true,
          appoinments_errors: null
        };
      case LOADING_STATUSES_APPOINTMENT:
        return {
          ...state,
          loading_get_appointment_statuses: true,
          get_appointment_statuses_error: null
        };
      case LOADING_UPDATE_APPOINTMENT:
        return {
          ...state,
          loading_update_appointment: true,
          update_appoinment_errors: null,
        };

      case LOADING_DELETE_APPOINTMENT:
        return {
          ...state,
          loading_delete_appointment: true,
            delete_appoinment_errors: null,
        };

      case LOADING_ADD_APPOINTMENT:
        return {
          ...state,
          loading_add_appointment: true,
          add_appoinment_errors: null,
        };
     
      case SET_APPOINTMENTS:
        return {
          ...state,
          appointments: action.payload,
          loading_appointments: false,
          appoinments_errors: null
        };
      case STATUSES_APPOINTMENT:
        return {
          ...state,
          statuses: action.payload,
          loading_get_appointment_statuses: false,
          get_appointment_statuses_error: null
        };

      case ADD_APPOINTMENT:
        let new_appointment_index = state.appointments.findIndex(x => x.id === action.payload.id);
        const appointmentNew = [...state.appointments];
        appointmentNew[new_appointment_index] = action.payload;
        return {
          ...state,
          appointments: [...appointmentNew],
          loading_add_appointment: false,
          add_appoinment_errors: null,
        };
      case UPDATE_APPOINTMENT:
        let appointment_index = state.appointments.findIndex(x => x.id === action.payload.id);
        const appointmentUpdate = [...state.appointments];
        appointmentUpdate[appointment_index] = action.payload;
        return {
          ...state,
          appointments: [...appointmentUpdate],
          loading_update_appointment: false,
          update_appoinment_errors: null,
        };
      case DELETE_APPOINTMENT:
        let appointment_del_index = state.appointments.findIndex(x => x.id === action.payload.id);
        const appointmentDeleteUpdate = [...state.appointments];
        appointmentDeleteUpdate[appointment_del_index] = action.payload;
          return {
            ...state,
            appointments: [...appointmentDeleteUpdate],
            loading_delete_appointment: false,
            delete_appoinment_errors: null,
          };
      case GET_APPOINTMENTS_ERROR:
        return {
          ...state,
          loading_appointments: false,
          appoinments_errors: action.payload.errors
        };
      case ADD_APPOINTMENT_ERROR:
        return {
          ...state,
          loading_add_appointment: false,
          add_appoinment_errors: action.payload.errors,
        };
      case UPDATE_APPOINTMENT_ERROR:
        return {
          ...state,
          loading_update_appointment: false,
          update_appoinment_errors: action.payload.errors,
        };
      case DELETE_APPOINTMENT_ERROR:
        return {
          ...state,
            loading_delete_appointment: false,
            delete_appoinment_errors: action.payload.errors,
        };
      case STATUSES_APPOINTMENT_ERROR:
        return {
          ...state,
          loading_get_appointment_statuses: false,
          get_appointment_statuses_error: action.payload.errors
        };
      
      default:
        return state;
    }
  }
  