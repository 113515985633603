import {
  SET_ACCOUNTS,
  LOADING_GET_ACCOUNTS,
  GET_ACCOUNTS_ERROR,
  ADD_ACCOUNT,
  ADD_ACCOUNT_ERROR,
  LOADING_ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  LOADING_UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_ERROR,
  DELETE_ACCOUNT,
  LOADING_DELETE_ACCOUNT,
  DELETE_ACCOUNT_ERROR
  } from '../types';
  
  const initialState = {
    accounts: [],
    account: {},
    loading_get_accounts: false,
    get_accounts_errors:null,
    loading_add_accounts: false,
    add_accounts_errors:null,
    loading_update_accounts: false,
    update_accounts_errors:null,
    loading_delete_accounts: false,
    delete_accounts_errors:null,

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_ACCOUNTS:
        return {
          ...state,
          loading_get_accounts: true,
          get_accounts_errors:null
        };
      case LOADING_ADD_ACCOUNT:
        return {
          ...state,
          loading_add_accounts: true,
          add_accounts_errors:null,
        };
      case LOADING_UPDATE_ACCOUNT:
        return {
          ...state,
          loading_update_accounts: true,
          update_accounts_errors:null,
        };
      case LOADING_DELETE_ACCOUNT:
        return {
          ...state,
          loading_delete_accounts: true,
          delete_accounts_errors:null,
        };
     
      case SET_ACCOUNTS:
        return {
          ...state,
          accounts: action.payload.data,
          loading_get_accounts: false,
          get_accounts_errors:null
        };
      case ADD_ACCOUNT:
          return {
            ...state,
            accounts:[...state.accounts,action.payload],
            loading_add_accounts: false,
            add_accounts_errors:null,
          };
      case UPDATE_ACCOUNT:
            let account_index = state.accounts.findIndex(x => x.id === action.payload.id);
            const accountUpdate = [...state.accounts];
            accountUpdate[account_index] = action.payload;
            return {
              ...state,
              accounts: [...accountUpdate],
              loading_update_accounts: false,
              update_accounts_errors:null,
      };
      case DELETE_ACCOUNT:
          let del_index = state.accounts.findIndex(x => x.id === action.payload.id);
          return {
            ...state,
            accounts: state.accounts.filter((item, index) => index !== del_index),
            loading_delete_accounts: false,
            delete_accounts_errors:null,
      };
      case GET_ACCOUNTS_ERROR:
        return {
          ...state,
          loading_get_accounts: false,
          get_accounts_errors: action.payload
        };
      case ADD_ACCOUNT_ERROR:
        return {
          ...state,
          loading_add_accounts: false,
          add_accounts_errors:action.payload.errors,
        };
      case UPDATE_ACCOUNT_ERROR:
        return {
          ...state,
          loading_update_accounts: false,
          update_accounts_errors: action.payload,
        };
      case DELETE_ACCOUNT_ERROR:
        return {
          ...state,
          loading_delete_accounts: false,
          delete_accounts_errors: action.payload,
        };
      
      default:
        return state;
    }
  }
  