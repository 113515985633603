import {
    LOADING_GET_CLIENTS,
    SET_CLIENTS,
    GET_CLIENTS_ERROR,
    ADD_CLIENT,
    LOADING_ADD_CLIENT,
    ADD_CLIENT_ERROR,
    UPDATE_CLIENT,
    UPDATE_CLIENT_ERROR,
    LOADING_UPDATE_CLIENT,
    DELETE_CLIENT,
    LOADING_DELETE_CLIENT,
    DELETE_CLIENT_ERROR,
    } from '../types';
    
    const initialState = {
      clients: [],
      loading_clients: false,
      clients_errors: null,
      loading_add_client: false,
      add_client_errors : null,
      loading_update_client: false,
      update_client_errors: null,
      loading_delete_client: false,
      delete_client_errors: null,
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_CLIENTS:
          return {
            ...state,
            loading_clients: true,
            clients_errors: null,
          };
        case LOADING_UPDATE_CLIENT:
          return {
            ...state,
            loading_update_client: true,
            update_client_errors: null,
          };
        case LOADING_DELETE_CLIENT:
          return {
            ...state,
            loading_delete_client: true,
            delete_client_errors: null,
          };
        case LOADING_ADD_CLIENT:
          return {
            ...state,
            loading_add_client: true,
            add_client_errors : null,
          };
        case SET_CLIENTS:
          return {
            ...state,
            clients: action.payload.data,
            loading_clients: false,
            clients_errors: null,
          };
        case ADD_CLIENT:
            return {
              ...state,
              clients:[...state.clients,action.payload],
              loading_add_client: false,
              add_client_errors : null,
            };
        case UPDATE_CLIENT:
          let client_index = state.clients.findIndex(x => x.id === action.payload.id);
          const clientUpdate = [...state.clients];
          clientUpdate[client_index] = action.payload;
          return {
            ...state,
            clients: [...clientUpdate],
            loading_update_client: false,
            update_client_errors: null,
            loading_add_client: false,
            add_client_errors : null,
          };
        case DELETE_CLIENT:
            let del = state.clients.findIndex(x => x.id === action.payload.id);
            return {
              ...state,
              clients: state.clients.filter((item, index) => index !== del),
              loading_delete_client: false,
              delete_client_errors: null,
            };
        case GET_CLIENTS_ERROR:
          return {
            ...state,
            loading_clients: false,
            clients_errors: action.payload,
          };
        case ADD_CLIENT_ERROR:
          return {
            ...state,
            loading_add_client: false,
            add_client_errors : action.payload,
          };
        case UPDATE_CLIENT_ERROR:
          return {
            ...state,
            loading_update_client: false,
            update_client_errors: action.payload,
          };
        case DELETE_CLIENT_ERROR:
          return {
            ...state,
            loading_delete_client: false,
            delete_client_errors: action.payload,
          };
       
        default:
          return state;
      }
    }
    