import {
  LOADING_GET_EMPLOYEES,
  SET_EMPLOYEES,
  GET_EMPLOYEES_ERROR,
  ADD_EMPLOYEE,
  LOADING_ADD_EMPLOYEE,
  ADD_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE,
  LOADING_UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_ERROR,
  LOADING_DELETE_EMPLOYEE,
  // LOADING_CHANGE_EMPLOYEE_STATUS,
  CHANGE_EMPLOYEE_STATUS,
  // CHANGE_EMPLOYEE_STATUS_ERROR
  LOADING_GET_DESIGNATIONS,
  GET_DESIGNATIONS_ERROR,
  SET_DESIGNATIONS,
  } from '../types';
  
  const initialState = {
    employees: [],
    loading_employees: false,
    employees_errors: null,
    loading_add_employee: false,
    add_employee_errors : null,
    loading_update_employee: false,
    update_employee_errors: null,
    loading_delete_employee: false,
    delete_employee_errors: null,
    designations: [],
    loading_designations: false,
    designations_errors: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_GET_EMPLOYEES:
        return {
          ...state,
          loading_employees: true,
          employees_errors: null,
        };
      case LOADING_UPDATE_EMPLOYEE:
        return {
          ...state,
          loading_update_employee: true,
          update_employee_errors: null,
        };
      case LOADING_DELETE_EMPLOYEE:
        return {
          ...state,
          loading_delete_employee: true,
          delete_employee_errors: null,
        };
      case LOADING_ADD_EMPLOYEE:
        return {
          ...state,
          loading_add_employee: true,
          add_employee_errors : null,
        };
      case SET_EMPLOYEES:
        return {
          ...state,
          employees: action.payload.data,
          loading_employees: false,
          employees_errors: null,
        };
      case ADD_EMPLOYEE:
          return {
            ...state,
            employees:[...state.employees,action.payload],
            loading_add_employee: false,
            add_employee_errors : null,
          };
      case UPDATE_EMPLOYEE:
        let employee_index = state.employees.findIndex(x => x.id === action.payload.id);
        const employeeUpdate = [...state.employees];
        employeeUpdate[employee_index] = action.payload;
        return {
          ...state,
          employees: [...employeeUpdate],
          loading_update_employee: false,
          update_employee_errors: null,
          loading_add_employee: false,
          add_employee_errors : null,
        };
      case  CHANGE_EMPLOYEE_STATUS:
        let employee_status_index = state.employees.findIndex(x => x.id === action.payload.id);
        const employeeUpdateStatus = [...state.employees];
        employeeUpdateStatus[employee_status_index] = action.payload;
        return {
          ...state,
          employees: [...employeeUpdateStatus],
         
        };
      case DELETE_EMPLOYEE:
          let del = state.employees.findIndex(x => x.id === action.payload.id);
          return {
            ...state,
            employees: state.employees.filter((item, index) => index !== del),
            loading_delete_employee: false,
            delete_employee_errors: null,
          };
      case GET_EMPLOYEES_ERROR:
        return {
          ...state,
          loading_employees: false,
          employees_errors: action.payload,
        };
      case ADD_EMPLOYEE_ERROR:
        return {
          ...state,
          loading_add_employee: false,
          add_employee_errors : action.payload,
        };
      case UPDATE_EMPLOYEE_ERROR:
        return {
          ...state,
          loading_update_employee: false,
          update_employee_errors: action.payload,
        };
      case DELETE_EMPLOYEE_ERROR:
         return {
          ...state,
          loading_delete_employee: false,
          delete_employee_errors: action.payload,
         };
        case LOADING_GET_DESIGNATIONS:
           return {
            ...state,
            loading_designations: true,
            designations_errors: null,
           };
        case SET_DESIGNATIONS:
            return {
              ...state,
              designations: action.payload.designations,
              loading_designations: false,
              designations_errors: null,
        };
        case GET_DESIGNATIONS_ERROR:
            return {
              ...state,
              loading_designations: false,
              designations_errors: action.payload,
        };
        default:
          return state;
        }
  }
  