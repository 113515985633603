import {
  SET_VARIANT,
  GET_VARIANT_ERRORS,
  LOADING_GET_VARIANT,
  LOADING_IMAGE_UPLOAD,
  IMAGE_UPLOAD_ERRORS,
  STOP_LOADING_IMAGE_UPLOAD,
  SET_ALL_VARIANTS,
  UPDATE_ALL_VARIANT,
  ADD_VARIANT_DATA,
  LOADING_ADD_VARIANT,
  ADD_VARIANT,
  UPDATE_VARIANT,
  ADD_VARIANT_ERRORS,
  GET_CUSTOM_VARIANTS_ERRORS,
  SET_CUSTOM_VARIANTS,
  LOADING_GET_CUSTOM_VARIANTS,
  LOADING_DELETE_VARIANT,
  DELETE_VARIANT_ERRORS,
  DELETE_VARIANT,
} from "../types";

const initialState = {
  all_variants: [],
  all_variants_meta: [],
  variant: {},
  items: [],
  total: 0,
  per_page: 0,
  loading_get_items: false,
  get_items_errors: null,
  variants: [],
  loading_get_variant: false,
  loading_image_upload: false,
  add_variant_loading: false,
  image_upload_errors: [],
  get_variant_errors: [],
  add_variant_errors: null,
  loadingDeleteVariant: false,
  deleteVariantErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_VARIANT:
      return {
        ...state,
        loading_get_variant: true,
        get_variant_errors: null,
      };
    case LOADING_GET_CUSTOM_VARIANTS:
      return {
        ...state,
        loading_get_items: true,
        get_items_errors: null,
      };
    case LOADING_IMAGE_UPLOAD:
      return {
        ...state,
        loading_image_upload: true,
        image_upload_errors: null,
      };
    case LOADING_ADD_VARIANT:
      return {
        ...state,
        add_variant_loading: true,
        add_variant_errors: null,
      };
    case ADD_VARIANT:
      return {
        ...state,
        variants: [...state.variants, action.payload],
        add_variant_loading: false,
        add_variant_errors: null,
      };
    case SET_CUSTOM_VARIANTS:
      return {
        ...state,
        items: action.payload.data,
        loading_get_items: false,
        get_items_errors: null,
      };
    case UPDATE_VARIANT:
      let ind = state.variants.findIndex((x) => x.id === action.payload.id);
      const variantUpdate = [...state.variants];
      variantUpdate[ind] = action.payload;
      return {
        ...state,
        variants: [...variantUpdate],
        add_variant_loading: false,
        add_variant_errors: null,
      };
    case STOP_LOADING_IMAGE_UPLOAD:
      return {
        ...state,
        loading_image_upload: false,
      };
    case GET_VARIANT_ERRORS:
      return {
        ...state,
        loading_get_variant: false,
        get_variant_errors: action.payload,
      };
    case IMAGE_UPLOAD_ERRORS:
      return {
        ...state,
        loading_image_upload: false,
        image_upload_errors: action.payload,
      };
    case SET_VARIANT:
      return {
        ...state,
        variant: action.payload,
        loading_get_variant: false,
        loading_image_upload: false,
        get_variant_errors: null,
      };
    case SET_ALL_VARIANTS:
      return {
        ...state,
        all_variants: action.payload.data,
        all_variants_meta: action.payload,
        total: action.payload.total,
        per_page: action.payload.per_page,
        loading_get_variant: false,
        loading_image_upload: false,
        get_variant_errors: null,
      };
    case UPDATE_ALL_VARIANT:
      let index = state.all_variants.findIndex(
        (x) => x.id === action.payload.id
      );
      const dataUpdate = [...state.all_variants];
      dataUpdate[index] = action.payload;
      return {
        ...state,
        all_variants: [...dataUpdate],
        add_variant_loading: false,
        add_variant_errors: null,
      };
    case ADD_VARIANT_ERRORS:
      return {
        ...state,
        add_variant_loading: false,
        add_variant_errors: action.payload.errors,
      };
    case GET_CUSTOM_VARIANTS_ERRORS:
      return {
        ...state,
        loading_get_items: false,
        get_items_errors: action.payload,
      };
    case ADD_VARIANT_DATA:
      return {
        ...state,
        variants: action.payload.variants,
      };
    case DELETE_VARIANT:
      return {
        ...state,
        variants: state.variants.filter(
          (item) => item.id !== action.payload.id
        ),
        all_variants: state.all_variants.filter(
          (item) => item.id !== action.payload.id
        ),
        loadingDeleteVariant: false,
        deleteVariantErrors: null,
      };
    case LOADING_DELETE_VARIANT:
      return {
        ...state,
        loadingDeleteVariant: true,
        deleteVariantErrors: null,
      };
    case DELETE_VARIANT_ERRORS:
      return {
        ...state,
        loadingDeleteVariant: false,
        deleteVariantErrors: action.payload,
      };

    default:
      return state;
  }
}
