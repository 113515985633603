import {
  LOADING_LOGIN_USER,
  LOGIN_USER_ERRORS,
  LOADING_GET_USERS,
  GET_USERS_ERRORS,
  SET_USERS,
  SET_USER,
  SET_USER_ERROR,
  LOADING_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  LOADING_UPDATE_USER,
  CREATE_USER,
  LOADING_CREATE_USER,
  CREATE_USER_ERRORS,
  DELETE_USER,
  DELETE_USER_ERRORS,
  LOADING_DELETE_USER,
  SET_USER_NEXT,
  ADD_USER_ERRORS,
  LOADING_ADD_USER,
  SET_ROLES,
  LOADING_GET_ROLES,
  GET_ROLES_ERRORS,
  DARK_MODE,
  SET_SETTINGS,
  GET_SETTINGS_ERROR,
  LOADING_GET_SETTINGS,
  LOADING_GET_DASHBOARD,
  SET_DASHBOARD,
  GET_DASHBOARD_ERROR,
  SET_USER_TODOS,
  LOADING_GET_USER_TODOS,
  GET_USER_TODOS_ERRORS,
  GET_USER_APPOINTMENTS_ERRORS,
  SET_USER_APPOINTMENTS,
  LOADING_GET_USER_APPOINTMENTS,
  LOADING_UPDATE_INFORMATION,
  UPDATE_INFORMATION,
  UPDATE_INFORMATION_ERRORS,
  UPDATE_EMPLOYEE,
  //  UPLOAD_PROFILE_IMAGE,
  LOADING_UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGE_ERRORS,
  REMOVE_PROFILE_IMAGE_ERRORS,
  LOADING_REMOVE_PROFILE_IMAGE,
  //user activities
  SET_USER_ACTIVITIES,
  LOADING_GET_USER_ACTIVITIES,
  GET_USER_ACTIVITIES_ERRORS,
  //PASSWORD RESET
  GET_PASSWORD_RESET_ERRORS,
  LOADING_GET_PASSWORD_RESET,
  SET_PASSWORD_RESET,
  //update app settings
  UPDATE_APP_SETTINGS,
  UPDATE_APP_SETTINGS_ERROR,
  LOADING_UPDATE_APP_SETTINGS,
  FILTER_DASHBOARD,
  SET_FORGOT_PASSWORD,
  //validate password reset code
  LOADING_VALIDATE_RESET_CODE,
  VALID_RESET_CODE,
  VALID_RESET_CODE_ERRORS,
  RESET_AUTH_ERROR_MESSAGE,
  SELECT_ACTIVE_PATIENT,
  STOP_LOADING_LOGIN_USER,
} from "../types";
// import axios from "axios";
// import apiInstance from "apiInstance";
import { apiInstance } from "../../services/api";
import jwtDecode from "jwt-decode";
import isServerError from "../../shared/functions/isServerError";

// apiInstance.defaults.baseURL = SERVER_URL;

export const loginUser = (userData, history, setSeverity) => (dispatch) => {
  dispatch({ type: LOADING_LOGIN_USER });
  apiInstance
    .post("user/login", userData)
    .then((res) => {
      localStorage.removeItem("sessionMessage");
      // setAuthorizationHeader(res.data.access_token);
      const FBIdToken = `Bearer ${res.data.access_token}`;
      localStorage.setItem("FBIdToken", FBIdToken);
      // apiInstance.defaults.baseURL = SERVER_URL;
      apiInstance.defaults.headers.common["Authorization"] = FBIdToken;
      dispatch({ type: SET_AUTHENTICATED });
      dispatch({
        type: SELECT_ACTIVE_PATIENT,
        payload: null,
      });
      dispatch(getUserData());
      // const getPreviousURl = localStorage.getItem("currentPath");
      // if (getPreviousURl) {
      //   history.push(`${getPreviousURl}`);
      // } else {
      history.push("/app");
      // }
    })
    .catch((error) => {
      localStorage.removeItem("sessionMessage");
      let err_data =
        typeof error.response === "undefined" ? error : error.response.data;
      setSeverity("error");
      dispatch({
        type: LOGIN_USER_ERRORS,
        payload: err_data,
      });
    });
};

export const stopAuthLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_LOGIN_USER });
};

export const resetAuthErrorMessage = () => (dispatch) => {
  dispatch({ type: RESET_AUTH_ERROR_MESSAGE });
};

export const valdateResetCode = (code, history, setSeverity) => (dispatch) => {
  dispatch({ type: LOADING_VALIDATE_RESET_CODE });
  apiInstance
    .post("user/validatetoken", { token: code })
    .then((res) => {
      dispatch({ type: VALID_RESET_CODE });
    })
    .catch((error) => {
      let err_data =
        typeof error.response === "undefined" ? error : error.response.data;
      setSeverity("error");
      dispatch({
        type: LOGIN_USER_ERRORS,
        payload: err_data,
      });
      dispatch({
        type: VALID_RESET_CODE_ERRORS,
        payload: err_data,
      });
      // setOpen(true);
      history.push("/forgotpassword");
    });
};

export const forgotPassword =
  (userData, history, setSeverity) => (dispatch) => {
    dispatch({ type: LOADING_LOGIN_USER });
    apiInstance
      .post("user/forgotpassword", userData)
      .then((res) => {
        setSeverity("success");
        dispatch({
          type: SET_FORGOT_PASSWORD,
          payload: res.data,
        });
      })
      .catch((error) => {
        let err_data =
          typeof error.response === "undefined" ? error : error.response.data;
        setSeverity("error");
        dispatch({
          type: LOGIN_USER_ERRORS,
          payload: err_data,
        });
      });
  };

export const resetPassword =
  (userData, pushMessageToSnackbar) => (dispatch) => {
    dispatch({ type: LOADING_GET_PASSWORD_RESET });
    apiInstance
      .post("user/reset", userData)
      .then((res) => {
        dispatch({ type: SET_PASSWORD_RESET });
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        pushMessageToSnackbar({
          text: isServerError(err_data.message),
          type: "error",
        });
        dispatch({
          type: GET_PASSWORD_RESET_ERRORS,
          payload: err_data,
        });
      });
  };
export const resetPasswordWithToken =
  (userData, history, setSeverity) => (dispatch) => {
    dispatch({ type: LOADING_GET_PASSWORD_RESET });
    apiInstance
      .post("user/resetwithtoken", userData)
      .then((res) => {
        setSeverity("success");
        dispatch({
          type: LOGIN_USER_ERRORS,
          payload: res.data,
        });
        history.push("/login");
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        dispatch({
          type: GET_PASSWORD_RESET_ERRORS,
          payload: err_data,
        });
      });
  };

//CRETE USER
export const updateInformation =
  (id, data, pushMessageToSnackbar) => (dispatch) => {
    dispatch({ type: LOADING_UPDATE_INFORMATION });

    apiInstance
      .put(`employee/${id}`, data)
      .then((res) => {
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
        dispatch({
          type: UPDATE_INFORMATION,
          payload: res.data.staff,
        });
        dispatch({
          type: UPDATE_EMPLOYEE,
          payload: res.data.staff,
        });
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        pushMessageToSnackbar({
          text: isServerError(err_data.message),
          type: "error",
        });
        dispatch({
          type: UPDATE_INFORMATION_ERRORS,
          payload: err_data,
        });
      });
  };
//upload profile image
export const uploadProfileImage = (id, form, pushMessageToSnackbar) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_UPLOAD_PROFILE_IMAGE });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    apiInstance
      .post(`employee/upload/${id}`, form, { headers: headers })
      .then((res) => {
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
        dispatch({
          type: UPDATE_INFORMATION,
          payload: res.data.staff,
        });
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        pushMessageToSnackbar({
          text: isServerError(err_data.message),
          type: "error",
        });
        dispatch({
          type: UPLOAD_PROFILE_IMAGE_ERRORS,
          payload: err_data,
        });
      });
  };
};
//upload profile image
export const removeProfileImage = (id, pushMessageToSnackbar) => (dispatch) => {
  dispatch({ type: LOADING_REMOVE_PROFILE_IMAGE });

  apiInstance
    .get(`employee/${id}/removeimage`)
    .then((res) => {
      pushMessageToSnackbar({
        text: res.data.message,
        type: "success",
      });
      dispatch({
        type: UPDATE_INFORMATION,
        payload: res.data.staff,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;

      pushMessageToSnackbar({
        text: isServerError(err_data.message),
        type: "error",
      });
      dispatch({
        type: REMOVE_PROFILE_IMAGE_ERRORS,
        payload: err_data,
      });
    });
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  apiInstance
    .get("/user/getauthuser")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data.user,
      });
      dispatch(getSettings());
    })
    .catch((err) => {
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
      dispatch(logoutUser());
    });
};
//GET USER TODOS
export const getAppointment = () => (dispatch) => {
  dispatch({ type: LOADING_GET_USER_APPOINTMENTS });
  apiInstance
    .get("/user/appointments")
    .then((res) => {
      dispatch({
        type: SET_USER_APPOINTMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_APPOINTMENTS_ERRORS,
        payload: err.response,
      });
    });
};
export const getActivities = () => (dispatch) => {
  dispatch({ type: LOADING_GET_USER_ACTIVITIES });
  apiInstance
    .get("/user/activities")
    .then((res) => {
      dispatch({
        type: SET_USER_ACTIVITIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_ACTIVITIES_ERRORS,
        payload: err.response,
      });
    });
};
export const getTodo = () => (dispatch) => {
  dispatch({ type: LOADING_GET_USER_TODOS });
  apiInstance
    .get("/user/todos")
    .then((res) => {
      dispatch({
        type: SET_USER_TODOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_TODOS_ERRORS,
        payload: err.response,
      });
    });
};
export const getSettings = () => (dispatch) => {
  dispatch({ type: LOADING_GET_SETTINGS });
  apiInstance
    .get("/setting")
    .then((res) => {
      dispatch({
        type: SET_SETTINGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SETTINGS_ERROR,
        payload: err.response,
      });
    });
};
export const getUserDataNextPage = (url) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_GET_USERS });
    apiInstance
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_USER_NEXT,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USERS_ERRORS,
          payload: err.response,
        });
      });
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  localStorage.removeItem("persist:root");
  delete apiInstance.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  dispatch({
    type: SELECT_ACTIVE_PATIENT,
    payload: null,
  });
};

export const setAuthenticated = () => (dispatch) => {
  dispatch({ type: SET_AUTHENTICATED });
};

//check expiration

export const checkAuth = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_GET_USERS });

      const token = localStorage.FBIdToken;
      if (token) {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken.exp);
        if (decodedToken.exp * 1000 < Date.now()) {
          //  console.log("log out");
          dispatch(logoutUser());
        } else {
          //  console.log("logged in");
          dispatch({ type: SET_AUTHENTICATED });
          apiInstance.defaults.headers.common["Authorization"] = token;
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_USERS_ERRORS,
        payload: error,
      });
    }
  };
};

//get users
export const getRoles = () => (dispatch) => {
  dispatch({ type: LOADING_GET_ROLES });
  apiInstance
    .get("roles")
    .then((res) => {
      dispatch({
        type: SET_ROLES,
        payload: res.data.roles,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      dispatch({
        type: GET_ROLES_ERRORS,
        payload: err_data,
      });
    });
};
//
//get users
export const getUsers = () => (dispatch) => {
  dispatch({ type: LOADING_GET_USERS });
  apiInstance
    .get("users")
    .then((res) => {
      dispatch({
        type: SET_USERS,
        payload: res.data,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      dispatch({
        type: GET_USERS_ERRORS,
        payload: err_data,
      });
    });
};
//get dashboard
export const getDashboard = () => (dispatch) => {
  dispatch({ type: LOADING_GET_DASHBOARD });
  apiInstance
    .get("dashboard")
    .then((res) => {
      dispatch({
        type: SET_DASHBOARD,
        payload: res.data,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      dispatch({
        type: GET_DASHBOARD_ERROR,
        payload: err_data,
      });
    });
};

//get dashboard
export const filterDashboard = (date, month) => (dispatch) => {
  dispatch({ type: LOADING_GET_DASHBOARD });
  apiInstance
    .get(`dashboard/filter/${date}/${month}`)
    .then((res) => {
      dispatch({
        type: FILTER_DASHBOARD,
        payload: res.data,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      dispatch({
        type: GET_DASHBOARD_ERROR,
        payload: err_data,
      });
    });
};
//
//UPDATE USER
export const updateUser = (user, pushMessageToSnackbar) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_USER });
  user.role = user.role.role;
  apiInstance
    .put(`user/${user.id}`, user)
    .then((res) => {
      pushMessageToSnackbar({
        text: res.data.message,
        type: "success",
      });
      dispatch({
        type: UPDATE_USER,
        payload: res.data.user,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: err.response,
      });
    });
};
//
//CRETE USER
export const createUser = (user, pushMessageToSnackbar) => (dispatch) => {
  dispatch({ type: LOADING_CREATE_USER });
  apiInstance
    .post("user", user)
    .then((res) => {
      pushMessageToSnackbar({
        text: res.data.message,
        type: "success",
      });
      dispatch({
        type: CREATE_USER,
        payload: res.data.user,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      // console.log(err_data);
      pushMessageToSnackbar({
        text: err_data.message,
        type: "error",
      });
      dispatch({
        type: CREATE_USER_ERRORS,
        payload: err_data,
      });
    });
};

//add user
export const addUsers = () => (dispatch) => {
  dispatch({ type: LOADING_ADD_USER });
  apiInstance
    .get("users")
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: SET_USERS,
        payload: res.data,
      });
    })
    .catch((error) => {
      let err_data = error.response === undefined ? error : error.response.data;
      dispatch({
        type: ADD_USER_ERRORS,
        payload: err_data,
      });
    });
};
//delete user
export const deleteUser =
  (password, email, element, pushMessageToSnackbar) => (dispatch) => {
    dispatch({ type: LOADING_DELETE_USER });
    let data = { email, password };
    apiInstance
      .post("authorize", data)
      .then((res) => {
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
        return apiInstance.delete(`user/${element}`);
      })
      .then((res) => {
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
        dispatch({
          type: DELETE_USER,
          payload: res.data.user,
        });
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        // console.log(err_data);
        pushMessageToSnackbar({
          text: err_data.message,
          type: "error",
        });
        dispatch({
          type: DELETE_USER_ERRORS,
          payload: err_data,
        });
      });
  };

//toggle dark mode
export const toggleDark = (theme) => {
  return async (dispatch) => {
    dispatch({
      type: DARK_MODE,
      payload: theme,
    });
    localStorage.setItem("Theme", theme);
  };
};

export const loadAppMode = (theme) => (dispatch) => {
  dispatch({
    type: DARK_MODE,
    payload: theme,
  });
};

//update app settings

//upload profile image
export const updateAppSettings = (form, pushMessageToSnackbar) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_UPDATE_APP_SETTINGS });

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    apiInstance
      .post(`setting`, form, { headers: headers })
      .then((res) => {
        pushMessageToSnackbar({
          text: res.data.message,
          type: "success",
        });
        dispatch({
          type: UPDATE_APP_SETTINGS,
          payload: res.data,
        });
      })
      .catch((error) => {
        let err_data =
          error.response === undefined ? error : error.response.data;
        pushMessageToSnackbar({
          text: err_data.message,
          type: "error",
        });
        dispatch({
          type: UPDATE_APP_SETTINGS_ERROR,
          payload: err_data,
        });
      });
  };
};
