import {
  LOADING_GET_SALES,
  SET_SALES,
  GET_SALES_ERROR,
  LOADING_GET_SALE,
  GET_SALE_ERROR,
  SET_SALE,
  ADD_SALE,
  LOADING_ADD_SALE,
  ADD_SALE_ERROR,
  UPDATE_SALE,
  UPDATE_SALE_ERROR,
  LOADING_UPDATE_SALE,
  DELETE_SALE,
  LOADING_DELETE_SALE,
  DELETE_SALE_ERROR,
  LOADING_GET_SALES_PAYMENT,
  GET_SALES_PAYMENT_ERROR,
  SET_SALES_PAYMENT,
  LOADING_GET_SOLD_ITEMS,
  SET_SOLD_ITEMS,
  GET_SOLD_ITEMS_ERROR,
} from "../types";

const initialState = {
  sales: [],
  sale: {},
  saleSummary: [],
  saleForSummary: [],
  payments: [],
  vats: [],
  services: [],
  discount: [],
  vouchers: [],
  products: [],
  loadingGetSale: false,
  getSaleErrors: null,
  salePayments: [],
  saleItems: [],
  saleServices: [],
  saleVouchers: [],
  transactionSummary: [],
  saleEmployee: {},
  salePatient: {},
  soldItems: [],
  totalItemsSold: 0,
  itemsSoldPerPage: 0,
  loadingSoldItems: false,
  soldItemsErrors: null,
  loading_sales: false,
  sales_errors: null,
  loading_add_sale: false,
  add_sale_errors: null,
  loading_update_sale: false,
  update_sale_errors: null,
  loading_delete_sale: false,
  delete_sale_errors: null,
  loading_payments: false,
  get_payments_error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_SALES:
      return {
        ...state,
        loading_sales: true,
        sales_errors: null,
      };
    case LOADING_GET_SALE:
      return {
        ...state,
        loadingGetSale: true,
        getSaleErrors: null,
      };
    case GET_SALE_ERROR:
      return {
        ...state,
        loadingGetSale: false,
        getSaleErrors: action.payload,
      };
    case SET_SALE:
      const {
        product,
        payments: payment,
        service,
        voucher,
        employee,
        patient,
      } = action.payload;
      // console.log(action.payload.data)
      return {
        ...state,
        sale: action.payload,
        salePayments: payment,
        saleItems: product,
        saleServices: service,
        saleVouchers: voucher,
        saleEmployee: employee,
        salePatient: patient,
        loadingGetSale: false,
        getSaleErrors: null,
      };
    case LOADING_GET_SALES_PAYMENT:
      return {
        ...state,
        loading_payments: true,
        get_payments_error: null,
      };
    case LOADING_UPDATE_SALE:
      return {
        ...state,
        loading_update_sale: true,
        update_sale_errors: null,
      };
    case LOADING_DELETE_SALE:
      return {
        ...state,
        loading_delete_sale: true,
        delete_sale_errors: null,
      };
    case LOADING_ADD_SALE:
      return {
        ...state,
        loading_add_sale: true,
        add_sale_errors: null,
      };
    case LOADING_GET_SOLD_ITEMS:
      return {
        ...state,
        loadingSoldItems: true,
        soldItemsErrors: null,
      };
    case SET_SALES:
      const {
        sales,
        payments,
        vouchers,
        products,
        transactionSummary,
        saleSummary,
        saleForSummary,
        vat_report,
        services,
        discount,
        trashed,
      } = action.payload.data;
      return {
        ...state,
        sales: sales,
        payments: payments,
        vouchers: vouchers,
        products: products,
        vats: vat_report,
        services: services,
        discount: discount,
        transactionSummary: transactionSummary,
        saleSummary: saleSummary,
        loading_sales: false,
        sales_errors: null,
        trashed: trashed,
        saleForSummary: saleForSummary,
      };
    case SET_SALES_PAYMENT:
      return {
        ...state,
        payments: action.payload.data,
        loading_payments: false,
        get_payments_error: null,
      };
    case SET_SOLD_ITEMS:
      return {
        ...state,
        soldItems: action.payload,
        // totalItemsSold: action.payload.total,
        // itemsSoldPerPage: action.payload.per_page,
        loadingSoldItems: false,
        soldItemsErrors: null,
      };
    case ADD_SALE:
      return {
        ...state,
        // sales: [...state.sales, action.payload],
        loading_add_sale: false,
        add_sale_errors: null,
      };
    case UPDATE_SALE:
      let sale_index = state.sales.findIndex((x) => x.id === action.payload.id);
      const saleUpdate = [...state.sales];
      saleUpdate[sale_index] = action.payload;
      return {
        ...state,
        sales: [...saleUpdate],
        loading_update_sale: false,
        update_sale_errors: null,
        loading_add_sale: false,
        add_sale_errors: null,
      };
    case DELETE_SALE:
      let del = state.sales.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        sales: state.sales.filter((item, index) => index !== del),
        loading_delete_sale: false,
        delete_sale_errors: null,
      };
    case GET_SALES_ERROR:
      return {
        ...state,
        loading_sales: false,
        sales_errors: action.payload,
      };
    case GET_SALES_PAYMENT_ERROR:
      return {
        ...state,
        loading_payments: false,
        get_payments_error: action.payload,
      };
    case ADD_SALE_ERROR:
      return {
        ...state,
        loading_add_sale: false,
        add_sale_errors: action.payload,
      };
    case UPDATE_SALE_ERROR:
      return {
        ...state,
        loading_update_sale: false,
        update_sale_errors: action.payload,
      };
    case DELETE_SALE_ERROR:
      return {
        ...state,
        loading_delete_sale: false,
        delete_sale_errors: action.payload,
      };
    case GET_SOLD_ITEMS_ERROR:
      return {
        ...state,
        loadingSoldItems: false,
        soldItemsErrors: action.payload,
      };

    default:
      return state;
  }
}
