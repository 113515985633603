import {SET_CURRENCIES, LOADING_GET_CURRENCIES, GET_CURRENCIES_ERROR} from '../types';
   
   const initialState = {
     currencies: [],
     loading_get_currencies: false,
     get_currencies_errors:null
 
   };
   
   export default function(state = initialState, action) {
     switch (action.type) {
       case LOADING_GET_CURRENCIES:
         return {
           ...state,
           loading_get_currencies: true,
           get_currencies_errors:null
         };
      
       case SET_CURRENCIES:
         return {
           ...state,
           currencies: action.payload.data,
           loading_get_currencies: false,
           get_currencies_errors:null
         };
       case GET_CURRENCIES_ERROR:
         return {
           ...state,
           loading_get_currencies: false,
           get_currencies_errors:action.payload
         };
       
       default:
         return state;
     }
   }
   